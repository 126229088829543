import React, { PropsWithChildren } from "react";

const CardGrid: React.FC<PropsWithChildren<any>> = (props) => {
  const { children } = props;

  return (
    <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      {children}
    </ul>
  );
};

export default CardGrid;
