import React, { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Id } from "convex/values";
import Button from "../components/system/Button";
import WebhookForm from "../components/WebhookForm";
import Overlay from "../components/system/Overlay";
import PayloadCard from "../components/PayloadCard";
import { useMutation, useQuery } from "../convex/_generated/react";
import CopyUrl from "../components/CopyUrl";

const Webhook = () => {
  const { id } = useParams<{ id: string }>();
  const _id = Id.fromString(id as string);

  const [showOverlay, setShowOverlay] = useState(false);

  const navigate = useNavigate();

  const webhook = useQuery("getWebhook", _id);

  const loading = webhook === undefined;

  const payloads = useQuery("getWebhookPayloads", _id) ?? [];
  const deleteWebhook = useMutation("deleteWebhook");
  const clearPayloads = useMutation("clearPayloads");

  const handleDelete = useCallback(() => {
    // TODO confrim
    deleteWebhook(_id).then(() => {
      navigate("/app/webhooks");
    });
  }, [_id, deleteWebhook, navigate]);

  const handleClear = useCallback(() => {
    clearPayloads(_id);
  }, [_id, clearPayloads]);

  const handleEdit = useCallback(() => {
    setShowOverlay(true);
  }, []);

  const handleClose = useCallback(() => {
    setShowOverlay(false);
  }, []);

  if (loading) {
    return null; // Loading...
  }

  if (webhook === null) {
    return <div>404</div>;
  }

  const hookUrl = `${
    window.location.origin
  }/.netlify/functions/whook?id=${webhook._id.toString()}`;

  return (
    <>
      <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
        <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-4">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {webhook.name}
            </h3>

            <p className={"mt-1 text-sm text-gray-500"}>{webhook.type}</p>

            <div className={"flex flex-row items-center"}>
              <p className="mt-1 mr-1 text-sm text-gray-500">{hookUrl}</p>
              <CopyUrl url={hookUrl} />
            </div>
          </div>
          <div className="ml-4 mt-4 flex flex-shrink-0">
            <Button text={"Edit"} variant={"white"} onClick={handleEdit} />
            <Button
              className="ml-3"
              text={"Clear"}
              variant={"white"}
              onClick={handleClear}
            />
            <Button
              className="ml-3"
              text={"Delete"}
              variant={"danger"}
              onClick={handleDelete}
            />
          </div>
        </div>
      </div>
      <div className="m-3 overflow-hidden bg-white sm:rounded-md">
        <ul className="">
          {payloads.map((p) => (
            <li className={"mb-2 rounded border"} key={p._id.toString()}>
              <PayloadCard payload={p} />
            </li>
          ))}
        </ul>
      </div>
      <Overlay
        visible={showOverlay}
        onClose={handleClose}
        title={"Edit Webhook"}
      >
        <WebhookForm webhook={webhook} onClose={handleClose} />
      </Overlay>
    </>
  );
};

export default Webhook;
