import React, { useMemo } from "react";
import ReactJson from "react-json-view";
import { NetlifyPayloadType } from "../types";
import { Payload } from "../common";

type Props = {
  type: string;
  payload: Payload;
};

const PayloadDetails = (props: Props) => {
  const { type, payload } = props;

  const payloadJson = useMemo(() => {
    return payload.payload ? JSON.parse(payload.payload) : null;
  }, [payload]);

  let summary;

  if (payloadJson && type === "netlify") {
    const np = payloadJson as NetlifyPayloadType;
    summary = (
      <div>
        {np.ssl_url ? np.ssl_url : np.url} {np.state}
      </div>
    );
  } else {
    summary = null;
  }

  return (
    <div className="mt-4">
      <div className={"mb-4"}>{summary}</div>
      {payloadJson && (
        <ReactJson
          style={{
            borderRadius: "4px",
            padding: "4px",
          }}
          src={payloadJson}
          theme={"grayscale:inverted"}
          indentWidth={2}
          collapsed={true}
          displayObjectSize={false}
          displayDataTypes={false}
          name={"payload"}
        />
      )}
    </div>
  );
};

export default PayloadDetails;
