import React, { useCallback } from "react";

import { Webhook } from "../common";
import { useNavigate } from "react-router-dom";

type Props = {
  webhook: Webhook;
};

const WebhookCard = (props: Props) => {
  const { webhook } = props;

  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate(`/app/webhooks/${webhook._id.toString()}`);
  }, [navigate, webhook._id]);

  return (
    <li
      className="col-span-1 cursor-pointer divide-y divide-gray-200 rounded-lg bg-white shadow hover:bg-gray-50"
      onClick={handleClick}
    >
      <div className="flex w-full items-center justify-between space-x-6 p-6">
        <div className="flex-1 truncate">
          <div className="flex items-center space-x-3">
            <h3 className="truncate text-sm font-medium text-gray-900">
              {webhook.name}
            </h3>
          </div>
          <p className="mt-1 truncate text-sm text-gray-500">{webhook.type}</p>
        </div>
        {/*<img className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0" src={person.imageUrl} alt="" />*/}
      </div>
    </li>
  );
};

export default WebhookCard;
