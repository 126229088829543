import React from "react";
import classNames from "classnames";

type Props = {
  text: string;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  onClick?: () => void;
  variant?: "primary" | "secondary" | "danger" | "white";
  className?: string;
};

const Button = (props: Props) => {
  const {
    text,
    size = "sm",
    onClick = () => {},
    variant = "primary",
    className,
  } = props;

  return (
    <button
      type="button"
      className={classNames(
        "inline-flex items-center rounded border border-transparent font-medium shadow-sm",
        {
          "bg-indigo-600 text-white hover:bg-indigo-700 focus:ring-indigo-500":
            variant === "primary",
          "bg-indigo-100 text-indigo-700 hover:bg-indigo-200 focus:ring-indigo-500":
            variant === "secondary",
          "bg-white text-gray-700 hover:bg-gray-50 focus:ring-indigo-500":
            variant === "white",
          "bg-red-100 text-red-700 hover:bg-red-200 focus:ring-red-500":
            variant === "danger",
        },
        "focus:outline-none focus:ring-2 focus:ring-offset-2",
        {
          "px-2.5 py-1.5 text-xs": size === "xs",
          "px-3 py-2 text-sm": size === "sm",
          "px-4 py-2 text-sm": size === "md",
          "px-4 py-2 text-base": size === "lg",
          "px-6 py-3 text-base": size === "xl",
        },
        className
      )}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default Button;
