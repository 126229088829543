import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "./system/Button";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button
      text={"Log In"}
      onClick={() =>
        loginWithRedirect({
          redirectUri: window.location.origin + "/app",
        })
      }
    />
  );
};

export default LoginButton;
