import React, { useMemo, useState } from "react";
import { IoMenu } from "react-icons/io5";
import { TbHeartbeat } from "react-icons/tb";
import { GiAnchor } from "react-icons/gi";
import Wordmark from "../components/Wordmark";
import classNames from "classnames";
import { useAuth0 } from "@auth0/auth0-react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import Activity from "./Activity";
import Webhooks from "./Webhooks";
import Webhook from "./Webhook";
import Profile from "./Profile";
import { IconType } from "react-icons";
import MobileSidebar from "./Shell/MobileSidebar";

export type NavigationItemType = {
  name: string;
  icon: IconType;
  pathname: string;
};

const navigation: NavigationItemType[] = [
  { name: "Activity", icon: TbHeartbeat, pathname: "/app" },
  { name: "Webhooks", icon: GiAnchor, pathname: "/app/webhooks" },
];

const Root = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const location = useLocation();

  const { user } = useAuth0();

  const { name, profileUrl } = useMemo(() => {
    return {
      name: user?.name ?? "",
      profileUrl: user?.picture,
    };
  }, [user?.name, user?.picture]);

  return (
    <>
      <div>
        <MobileSidebar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          navigation={navigation}
        />

        {/* Static sidebar for desktop */}
        <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex min-h-0 flex-1 flex-col bg-gray-800">
            <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
              <div className="flex flex-shrink-0 items-center px-4">
                <Wordmark color={"text-white"} />
              </div>
              <nav className="mt-5 flex-1 space-y-1 px-2">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.pathname}
                    className={classNames(
                      item.pathname === location.pathname
                        ? "bg-gray-900 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "group flex items-center rounded-md px-2 py-2 text-sm font-medium"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.pathname === location.pathname
                          ? "text-gray-300"
                          : "text-gray-400 group-hover:text-gray-300",
                        "mr-3 h-6 w-6 flex-shrink-0"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </Link>
                ))}
              </nav>
            </div>
            <div className="flex flex-shrink-0 bg-gray-700 p-4">
              <Link
                to={"/app/settings"}
                className="group block w-full flex-shrink-0"
              >
                <div className="flex items-center">
                  <div>
                    <img
                      className="inline-block h-9 w-9 rounded-full"
                      src={profileUrl}
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-white">{name}</p>
                    <p className="text-xs font-medium text-gray-300 group-hover:text-gray-200">
                      Settings
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col md:pl-64">
          <div className="sticky top-0 z-10 bg-gray-100 pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <IoMenu className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <main className="flex-1">
            <div className="py-6">
              <Routes>
                <Route path={"/"} element={<Activity />} />
                <Route path={"webhooks"} element={<Webhooks />} />
                <Route path={"webhooks/:id"} element={<Webhook />} />
                <Route path={"settings"} element={<Profile />} />
              </Routes>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Root;
