import { useCallback, useMemo, useState } from "react";
import * as Bowser from "bowser";
import { useMutation, useQuery } from "../convex/_generated/react";

// https://gist.github.com/Klerith/80abd742d726dd587f4bd5d6a0ab26b6
function urlBase64ToUint8Array(base64String: string) {
  var padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  var base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

const browser = Bowser.parse(window.navigator.userAgent);

const useWebPush = () => {
  const [grantedPermission, setGrantedPermission] = useState(
    Notification.permission === "granted"
  );

  const createSub = useMutation("createSubscription");
  const subs = useQuery("getUserSubscriptions", null) ?? [];

  const isSupported = useMemo(() => {
    return "serviceWorker" in navigator && "PushManager" in window;
  }, []);

  const pushSubscription = useCallback(() => {
    return navigator.serviceWorker.ready
      .then((registration) => {
        const subscribeOptions = {
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(
            "BNd_Dvko0MbsAicdGFEVIQhGE5ZCkgNptZQx5I3u6uSyz1ibVSepWx9PrbqAlTsdlYY1ztW824YoNz-joEKqfJ0"
          ),
        };

        return registration.pushManager.subscribe(subscribeOptions);
      })
      .then(function (pushSubscription) {
        console.log(
          "Received PushSubscription: ",
          JSON.stringify(pushSubscription)
        );
        return pushSubscription;
      });
  }, []);

  const askPermission = useCallback(async () => {
    const permission = await Notification.requestPermission();

    const granted = permission === "granted";

    if (granted) {
      setGrantedPermission(true);
      const sub = await pushSubscription();
      await createSub({
        name: `${browser.browser.name} on ${browser.os.name}`,
        browser: JSON.stringify(browser),
        object: JSON.stringify(sub),
      });
    }
  }, [createSub, pushSubscription]);

  return {
    isSupported,
    grantedPermission,
    askPermission,
    activeSubscriptions: subs,
  };
};

export default useWebPush;
