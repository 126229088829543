import React from "react";
import LogoutButton from "../components/LogoutButton";
import ProfileInfo from "../components/ProfileInfo";
import Button from "../components/system/Button";
import useWebPush from "../hooks/useWebPush";

const Profile = () => {
  const { grantedPermission, askPermission, activeSubscriptions } =
    useWebPush();

  return (
    <>
      <ProfileInfo />
      <div>
        <LogoutButton />
        {grantedPermission && <p>Desktop Notifications Enabled</p>}
        <Button text={"Allow Desktop Notifications"} onClick={askPermission} />
        {JSON.stringify(activeSubscriptions)}
      </div>
    </>
  );
};

export default Profile;
