import React, { useEffect } from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import useConvexAuth from "./hooks/useConvexAuth";
import ProtectedRoute from "./components/ProtectedRoute";
import Marketing from "./screens/Marketing";
import Root from "./screens/Root";
import { Slide, toast, ToastContainer } from "react-toastify";
import { SubjectEvent } from "./types";
import { Subject } from "rxjs";
import ServiceWorkerUpdate from "./components/toasts/ServiceWorkerUpdate";

import "react-toastify/dist/ReactToastify.min.css";

type Props = {
  subject: Subject<SubjectEvent>;
};

const App = (props: Props) => {
  const { subject } = props;

  useConvexAuth();

  useEffect(() => {
    const sub = subject.subscribe((event) => {
      if (event.type === "sw:installed") {
        console.log("Service Worker Installed");
      } else if (event.type === "sw:update") {
        const reg = event.reg;
        toast(
          <ServiceWorkerUpdate
            handleUpdate={() => {
              const registrationWaiting = reg?.waiting;

              if (registrationWaiting) {
                registrationWaiting.postMessage({ type: "SKIP_WAITING" });

                registrationWaiting.addEventListener("statechange", (e) => {
                  // @ts-ignore
                  if (e.target.state === "activated") {
                    window.location.reload();
                  }
                });
              }
            }}
          />,
          {
            position: "top-right",
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: true,
          }
        );
      }
    });

    return () => {
      sub.unsubscribe();
    };
  }, [subject]);

  return (
    <BrowserRouter>
      <div className="min-h-full">
        <Routes>
          <Route path={"/"} element={<Marketing />} />
          <Route
            path={"/app/*"}
            element={
              <ProtectedRoute>
                <Root />
              </ProtectedRoute>
            }
          />
        </Routes>
        <ToastContainer transition={Slide} />
      </div>
    </BrowserRouter>
  );
};

export default App;
