import React from "react";
import Button from "../system/Button";

type Props = {
  handleUpdate: () => void;
};

const ServiceWorkerUpdate = (props: Props) => {
  const { handleUpdate } = props;

  return (
    <div className={"flex flex-row p-2"}>
      <p className={"mr-2"}>There is a new version available</p>
      <Button text={"Update"} onClick={handleUpdate} />
    </div>
  );
};

export default ServiceWorkerUpdate;
