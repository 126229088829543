import React from "react";
import PayloadCard from "../components/PayloadCard";
import ListHeader from "../components/system/ListHeader";
import { useQuery } from "../convex/_generated/react";

const Activity = () => {
  const payloads = useQuery("getUserPayloads", 10) || [];

  return (
    <>
      <ListHeader
        title={"Webhook Activity"}
        // action={<Button size={"sm"} text={"Clear"} variant={"white"} />}
      />
      <div className="m-3 overflow-hidden bg-white sm:rounded-md">
        <ul>
          {payloads.map((p) => (
            <li className={"mb-2 rounded border"} key={p._id.toString()}>
              <PayloadCard payload={p} />
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Activity;
