import React from "react";
import ReactDOM from "react-dom/client";
import "./tailwind.css";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";
import { ConvexProvider, ConvexReactClient } from "convex/react";

import convexConfigDev from "./convex.json";
import convexConfigProd from "./convex.prod.json";

import { SubjectEvent } from "./types";
import { Subject } from "rxjs";

const isProduction = process.env.NODE_ENV === "production";

const convex = new ConvexReactClient(
  isProduction ? convexConfigProd.origin : convexConfigDev.origin
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const subject = new Subject<SubjectEvent>();

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="whook.us.auth0.com"
      clientId="XgOZJS2NxmfLFNOitAgtnxUQE6Nrrj8t"
      redirectUri={window.location.origin}
      cacheLocation="localstorage"
    >
      <ConvexProvider client={convex}>
        <App subject={subject} />
      </ConvexProvider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onSuccess: () => {
    subject.next({
      type: "sw:installed",
    });
  },
  onUpdate: (registration) => {
    subject.next({
      type: "sw:update",
      reg: registration,
    });
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
