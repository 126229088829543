import React, { useCallback } from "react";

import Wordmark from "../components/Wordmark";
import LoginButton from "../components/LoginButton";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "../components/system/Button";
import { useNavigate } from "react-router-dom";

type Props = {};

const Home = (props: Props) => {
  const { isAuthenticated } = useAuth0();

  const navigate = useNavigate();

  const handleToApp = useCallback(() => {
    navigate("/app");
  }, [navigate]);

  return (
    <div className={"m-8 flex flex-row justify-between"}>
      <div />
      <Wordmark />
      {isAuthenticated ? (
        <Button text={"Go to App"} onClick={handleToApp} />
      ) : (
        <LoginButton />
      )}
    </div>
  );
};

export default Home;
