import React, { ChangeEvent, useCallback, useState } from "react";
import { Webhook } from "../common";
import Button from "./system/Button";
import { useNavigate } from "react-router-dom";
import { useMutation } from "../convex/_generated/react";

type Props = {
  webhook?: Webhook;
  onClose: () => void;
};

const WebhookForm = (props: Props) => {
  const { webhook, onClose } = props;

  const navigate = useNavigate();

  const createWebhook = useMutation("createWebhook");
  const updateWebhook = useMutation("updateWebhook");

  const [name, setName] = useState(webhook?.name ?? "");
  const [type, setType] = useState(webhook?.type ?? "generic");

  const handleNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setName(value);
    },
    []
  );

  const handleTypeChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const value = event.target.value;
      setType(value);
    },
    []
  );

  const handleCancel = onClose;

  const handleSave = useCallback(() => {
    createWebhook({
      name,
      type,
    }).then((response) => {
      if (response?._id) {
        navigate(`/app/webhooks/${response._id.toString()}`);
      }
      onClose();
    });
  }, [createWebhook, name, navigate, onClose, type]);

  const handleUpdate = useCallback(() => {
    if (webhook) {
      updateWebhook(webhook._id, {
        name,
        type,
      }).then(() => {
        onClose();
      });
    }
  }, [name, onClose, type, updateWebhook, webhook]);

  return (
    <div className="space-y-8 divide-y divide-gray-200">
      <div className="space-y-8 divide-y divide-gray-200">
        <div>
          <div>
            <p className="mt-1 text-sm text-gray-500">
              Create a new webhook destination
            </p>
          </div>

          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-5">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={name}
                  onChange={handleNameChange}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="sm:col-span-6">
              <label
                htmlFor="location"
                className="block text-sm font-medium text-gray-700"
              >
                Type
              </label>
              <select
                id="type"
                name="type"
                value={type}
                onChange={handleTypeChange}
                className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              >
                <option value={"generic"}>Generic</option>
                <option value={"netlify"}>Netlify</option>
              </select>
              <p className="mt-2 text-sm text-gray-500">
                Details on the webhook payload
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <Button text={"Cancel"} variant={"white"} onClick={handleCancel} />
          <Button
            className={"ml-3"}
            text={"Save"}
            onClick={webhook ? handleUpdate : handleSave}
          />
        </div>
      </div>
    </div>
  );
};

export default WebhookForm;
