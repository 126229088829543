import React from "react";
import { GiAnchor } from "react-icons/gi";
import classNames from "classnames";

type Props = {
  color?: string;
};

const Wordmark = (props: Props) => {
  const { color = "text-black" } = props;

  return (
    <div className={"flex flex-row pt-2"}>
      <div className={"rotate-45 transform"}>
        <GiAnchor size={"2.125rem"} className={color} />
      </div>
      <span className={classNames("text-4xl", color)}>hook</span>
    </div>
  );
};

export default Wordmark;
