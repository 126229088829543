import React from "react";

import { Payload } from "../common";
import PayloadDetails from "./PayloadDetails";
import { useQuery } from "../convex/_generated/react";

type Props = {
  payload: Payload;
};

const PayloadCard = (props: Props) => {
  const { payload } = props;

  const webhook = useQuery("getWebhook", payload.webhookId);

  return (
    <div className="flex flex-col p-4">
      <p className="text-md truncate font-medium">{webhook?.name || ""}</p>
      <p className="flex items-center text-sm text-gray-500">
        {new Date(payload.received).toLocaleString()}
      </p>
      <PayloadDetails type={webhook?.type ?? ""} payload={payload} />
    </div>
  );
};

export default PayloadCard;
