import React, { useCallback, useMemo, useState } from "react";
import WebhookCard from "../components/WebhookCard";
import ListHeader from "../components/system/ListHeader";
import Button from "../components/system/Button";
import CardGrid from "../components/system/CardGrid";
import Overlay from "../components/system/Overlay";
import WebhookForm from "../components/WebhookForm";
import { useQuery } from "../convex/_generated/react";

const Webhooks = () => {
  const [showOverlay, setShowOverlay] = useState(false);

  const result = useQuery("getUserWebhooks");

  const loading = result === undefined;
  const webhooks = result ?? [];

  const handleCreate = useCallback(() => {
    setShowOverlay(true);
  }, []);

  const handleClose = useCallback(() => {
    setShowOverlay(false);
  }, []);

  const newButton = useMemo(() => {
    return <Button text={"New"} size={"sm"} onClick={handleCreate} />;
  }, [handleCreate]);

  const emptyState = useMemo(() => {
    if (webhooks.length === 0 && !loading) {
      return (
        <div className="text-center">
          <h3 className="mt-2 text-sm font-medium text-gray-900">
            No webhooks
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Get started by creating a new webhook destination.
          </p>
          <div className="mt-6">
            <Button text={"New Webhook"} onClick={handleCreate} />
          </div>
        </div>
      );
    } else {
      return null;
    }
  }, [handleCreate, loading, webhooks.length]);

  return (
    <>
      <ListHeader title={"Webhooks"} action={newButton} />
      <div className={"p-8"}>
        {emptyState}
        <CardGrid>
          {webhooks.map((w) => (
            <WebhookCard key={w._id.toString()} webhook={w} />
          ))}
        </CardGrid>
      </div>
      <Overlay
        visible={showOverlay}
        onClose={handleClose}
        title={"New Webhook"}
      >
        <WebhookForm onClose={handleClose} />
      </Overlay>
    </>
  );
};

export default Webhooks;
