/* eslint-disable */
/**
 * Generated React hooks.
 *
 * THIS CODE IS AUTOMATICALLY GENERATED.
 *
 * Generated by convex@0.1.6.
 * To regenerate, run `npx convex codegen`.
 * @module
 */

import type clearPayloads from "../clearPayloads";
import type createPayload from "../createPayload";
import type createSubscription from "../createSubscription";
import type createWebhook from "../createWebhook";
import type deleteSubscription from "../deleteSubscription";
import type deleteWebhook from "../deleteWebhook";
import type getPayload from "../getPayload";
import type getSubscription from "../getSubscription";
import type getUser from "../getUser";
import type getUserPayloads from "../getUserPayloads";
import type getUserPayloadsSince from "../getUserPayloadsSince";
import type getUserSubscriptions from "../getUserSubscriptions";
import type getUserWebhooks from "../getUserWebhooks";
import type getWebhook from "../getWebhook";
import type getWebhookPayloads from "../getWebhookPayloads";
import type storeUser from "../storeUser";
import type updateSubscription from "../updateSubscription";
import type updateUser from "../updateUser";
import type updateWebhook from "../updateWebhook";

// This jumpstarts TypeScript completion of the convex/values entry point.
import type { Id } from "convex/values";
if ("VSCode" === "sees" + "that this module is imported") {
  const msg = "you get great autocompletion for" + ("Id" as unknown as Id);
  throw new Error(msg);
}
import type { OptimisticLocalStore as GenericOptimisticLocalStore } from "convex/browser";
import type { ClientMutation, ClientQuery } from "convex/server";

/**
 * A type describing your app's public Convex API.
 *
 * This `ConvexAPI` type includes information about the arguments and return
 * types of your app's query and mutation functions.
 *
 * This type should be used with type-parameterized classes like
 * `ConvexReactClient` to create app-specific types.
 */
export type ConvexAPI = {
  queries: {
    getPayload: ClientQuery<typeof getPayload>;
    getSubscription: ClientQuery<typeof getSubscription>;
    getUser: ClientQuery<typeof getUser>;
    getUserPayloads: ClientQuery<typeof getUserPayloads>;
    getUserPayloadsSince: ClientQuery<typeof getUserPayloadsSince>;
    getUserSubscriptions: ClientQuery<typeof getUserSubscriptions>;
    getUserWebhooks: ClientQuery<typeof getUserWebhooks>;
    getWebhook: ClientQuery<typeof getWebhook>;
    getWebhookPayloads: ClientQuery<typeof getWebhookPayloads>;
  };
  mutations: {
    clearPayloads: ClientMutation<typeof clearPayloads>;
    createPayload: ClientMutation<typeof createPayload>;
    createSubscription: ClientMutation<typeof createSubscription>;
    createWebhook: ClientMutation<typeof createWebhook>;
    deleteSubscription: ClientMutation<typeof deleteSubscription>;
    deleteWebhook: ClientMutation<typeof deleteWebhook>;
    storeUser: ClientMutation<typeof storeUser>;
    updateSubscription: ClientMutation<typeof updateSubscription>;
    updateUser: ClientMutation<typeof updateUser>;
    updateWebhook: ClientMutation<typeof updateWebhook>;
  };
};

import { makeUseQuery, makeUseMutation, makeUseConvex } from "convex/react";

/**
 * Load a reactive query within a React component.
 *
 * This React hook contains internal state that will cause a rerender whenever
 * the query result changes.
 *
 * This relies on the {@link ConvexProvider} being above in the React component tree.
 *
 * @param name - The name of the query function.
 * @param args - The arguments to the query function.
 * @returns `undefined` if loading and the query's return value otherwise.
 */
export const useQuery = makeUseQuery<ConvexAPI>();

/**
 * Construct a new {@link ReactMutation}.
 *
 * Mutation objects can be called like functions to request execution of the
 * corresponding Convex function, or further configured with
 * [optimistic updates](https://docs.convex.dev/using/optimistic-updates).
 *
 * The value returned by this hook is stable across renders, so it can be used
 * by React dependency arrays and memoization logic relying on object identity
 * without causing rerenders.
 *
 * This relies on the {@link ConvexProvider} being above in the React component tree.
 *
 * @param name - The name of the mutation.
 * @returns The {@link ReactMutation} object with that name.
 */
export const useMutation = makeUseMutation<ConvexAPI>();

/**
 * Get the {@link ConvexReactClient} within a React component.
 *
 * This relies on the {@link ConvexProvider} being above in the React component tree.
 *
 * @returns The active {@link ConvexReactClient} object, or `undefined`.
 */
export const useConvex = makeUseConvex<ConvexAPI>();

/**
 * A view of the query results currently in the Convex client for use within
 * optimistic updates.
 */
export type OptimisticLocalStore = GenericOptimisticLocalStore<ConvexAPI>;
